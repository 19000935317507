import { configureStore } from "@reduxjs/toolkit";
import { landlords } from "./landlords";
import { listing } from "./listing";
import { location } from "./location";
import { proparty } from "./proparty";

const Store = configureStore({
  reducer: {
    city: location.reducer,
    listing:listing.reducer,
    propartyDetails:proparty.reducer,
    landlords: landlords.reducer,
  },
});

export default Store;
