export const IDLE = "idle";
export const LOADING = "loading";
export const STRIPE = "STRIPE";
export const AMAZON_PAYMENT_SERVICE = "AMAZON_PAYMENT_SERVICE";
export const PROPERTY_CHECKOUT_PAGE = "property-checkout";
export const PROPERTY_PAYMENT_PAGE = "property-payment";
export const SUCCESS_SAVE_CHANGE = "Changes saved successfully!";
export const SOMETHING_WENT_WRONG = "Something went wrong! Please try again.";
export const KSA = "Saudi Arabia";
export const KSA_SUPPORT_TEL_NO = "+966115079609";
export const KSA_SUPPORT_NO = "+966 11 507 9609";
export const DUBAI_SUPPORT_TEL_NO = "+97148369556";
export const DUBAI_SUPPORT_NO = "+971 48369556";
export const DEFAULT_CURRENCY = "AED";
export const TAGRID = "tagrid";
export const LOCAL_STORAGE_ITEMS = {
  PROPERTY_DETAILS: "property-details",
  BOOKING_DETAILS: "booking-details",
  BOOKING_DATA: "bookingData",
  LINE_ITEMS: "line_items",
  UNDEFINED: "undefined",
  CURRENCY: "currency",
  SKYWARD_STORAGE_KEY: "skywardsTag",
  LAST_PROMO_DIALOG_SHOWN: "lastPromoDialogShown"
};
export const ANCILLARY_SERVICES_VISIBLE_CITIES = ['Dubai', 'Abu Dhabi'];
export const CLEANING_VISIBLE_CITIES = ['Dubai', 'Abu Dhabi'];
export const MONTHLY = "MONTHLY";
export const DAILY = "DAILY";
export const GOOGLE_RECAPTCHA_ACTIONS = {
  PROMO_DIALOG_EMAIL_SUBMIT: 'PROMO_DIALOG_EMAIL_SUBMIT'
}
export const POINT_OF_SALE = "pointofsale";