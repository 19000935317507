import React, { createContext, useEffect, useReducer } from "react";
import { uaeFlag } from "../helpers/cdn";

import { useSearchParams } from "react-router-dom";
import { capitalCased, countryNames, dashCased } from "../helpers/utils";

const silkhausCities = ["dubai", "abu-dhabi", "riyadh"];
const applicablePaths = ["", "our-properties"];
// Add new
const ignorePaths = ["/404", "/property-detail", "/who-we-are", "/how-it-works", "/landlord", "/sitemap",
  "/success-payment", "/success-ancillary-payment", "/terms-and-conditions", "/featured-towers", "featured-towers/marina-vista",
  "/privacy-policy", "/corporate-and-groups", "/customer_feedback", "/onboarding_feedback", "/manage-booking", "/verify-payment", "/long-term-stays"];

const GlobalContext = createContext();

function extractCityStateCurrency(url) {
  if (url.includes("our-properties")) {
    url = url.replace("/our-properties", "");
  }
  const urlObject = new URL(url);
  const pathSegments = urlObject.pathname
    .split("/")
    .filter((segment) => segment.trim() !== "");
  let city = pathSegments[0] || null;
  const neighbourhood = pathSegments[1] || null;

  let currency = urlObject.searchParams.get("currency") || null;

  if (currency) {
    if (!countryNames.map(obj => obj.name).includes(currency || ''))
      currency = localStorage.getItem("query") || "AED"

    currency = currency.toUpperCase();
  }

  if (city) {
    city = city
      ?.toLowerCase()
      ?.split("-")
      ?.map((word) => word?.toLocaleLowerCase())
      ?.join("-");
    if (!silkhausCities.includes(city)) city = localStorage.getItem("city") || "dubai";
    city = capitalCased(city);
  }

  return { city, neighbourhood, currency };
}

const { city, neighbourhood, currency } = extractCityStateCurrency(
  window.location.href
);

const flagPic = countryNames.find((el) => el.name === currency)?.image;

if (city) {
  localStorage.setItem("city", city || "Dubai");
}
if (currency && flagPic) {
  localStorage.setItem("query", currency || "AED");
  localStorage.setItem("pic", flagPic || uaeFlag);
}

const initialState = {
  city: city || localStorage.getItem("city") || "Dubai",
  currency: currency || localStorage.getItem("query") || "AED",
  pic: flagPic || localStorage.getItem("pic") || uaeFlag,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "currency":
      return { ...state, currency: action.payload };
    case "pic":
      return { ...state, pic: action.payload };
    case "city":
      return { ...state, city: action.payload };
    default:
      return state;
  }
};

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("currency")) {
      searchParams.delete("currency");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    const urlPath = new URL(window.location.href).pathname;
    const urlPathCheck = urlPath.split("/");
    const urlPathCheckLength = urlPathCheck.length
    if (initialState.city && !ignorePaths.includes(urlPath) &&
      !silkhausCities.includes(urlPathCheck[urlPathCheckLength-1]) &&
      applicablePaths.includes(urlPathCheck[urlPathCheckLength-2])) {
      const convertedString = dashCased(initialState.city);
      const replaceUrl = urlPathCheck[urlPathCheckLength - 2] !== ""
        ? `/${urlPathCheck[urlPathCheckLength - 2]}/${convertedString || "Dubai"}`
        : `/${convertedString || "Dubai"}`;
      window.history.replaceState(null, "", replaceUrl);
    }
  }, []);

  useEffect(() => {
    if (state.city) {
      localStorage.setItem("city", state.city || "Dubai");
    }
  }, [state.city]);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalContextProvider };