import React, { lazy, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import "./App.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "./ShareModules/Layout/Header/Header";
import { useEffect } from "react";
import { fetch_cities } from "./redux/location";
import { useDispatch, useSelector } from "react-redux";
import { StripePaymentProvider } from "./CommonComponent/StripePaymentContext";
import { v4 as uuid } from 'uuid';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18/i18n";


const About = lazy(() => import("./Components/AboutUs/About"));
const ManageBooking = lazy(() => import("./Components/ManageBooking/Index"));
const LongTermStays = lazy(() => import("./Components/LongTermStays/index"));

const HowItWorks = lazy(() => import("./Components/HowItWorks/HowItWorks"));
const OurProperties = lazy(() =>
  import("./Components/OurProperties/OurProperties")
);
const Landlord = lazy(() => import("./Components/LandlordNew/Landlord"));
const Index = lazy(() => import("./Components/Home/Index"));
const PropertyDetail = lazy(() =>
  import("./Components/PropertyDetail/PropertyDetail")
);
const PropertyCheckout = lazy(() =>
  import("./Components/PropertyCheckout/PropertyCheckout")
);
const PropertyPayment = lazy(() =>
  import("./Components/PropertyCheckout/PropertyPayment")
);

const VerifyPayment = lazy(() => import("./Components/VerifyPayment"));

const SuccessPayment = lazy(() => import("./Components/SuccesPayment"));
const SuccessAncillaryPayment = lazy(() => import("./Components/SuccessAncillaryPayment"));
const TermsConditions = lazy(() =>
  import("./Components/TermsConditions/TermsConditions")
);

const TowerProfile = lazy(() => import("./Components/TowerProfile/TowerProfile"));
const Sitemap = lazy(() => import("./Components/Sitemaps/Sitemap"));

const BusinessGroupIndex = lazy(() =>
  import("./Components/BusinessAndGroup/Index")
);
const BusinessAndGroup = lazy(() =>
  import("./Components/BusinessAndGroup/BusinessAndGroup")
);
const CorporateInquiry = lazy(() =>
  import("./Components/BusinessAndGroup/CorporateInquiry")
);
const GroupInquiry = lazy(() =>
  import("./Components/BusinessAndGroup/GroupInquiry")
);
const PrivacyPolicy = lazy(() =>
  import("./Components/PrivacyPolicy/PrivacyPolicy")
);
const NotFoundPage = lazy(() => import("./Components/NotFound/NotFoundPage"));
const CustomerReview = lazy(() => import("./Components/CustomerReview"));
const OwnerReview = lazy(() => import("./Components/OwnerReview"));

const ProgressBar1 = () => {
  return (
    <div className="top-0 position-fixed w-100 start-0 zindex-9999">
      <div
        style={{ width: "{dynamic}-%", height: "3px", background: "#542686" }}
      />
    </div>
  );
};

function App() {
  const dispatch = useDispatch();
  const { cities_data } = useSelector((s) => s?.city);

  useEffect(() => {
    dispatch(fetch_cities());
  }, [dispatch]);

  useEffect(() => {
    if (!localStorage.getItem("preferredLanguage")) {
      localStorage.setItem("preferredLanguage", 'en');
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage(localStorage.getItem("preferredLanguage"));
    }
  }, []);

  useEffect(() => {
    const unique_user_id = uuid();
    if (localStorage.getItem("unique_ga_user") === null) {
      localStorage.setItem("unique_ga_user", unique_user_id);
    }
  }, []);

  const cityData = cities_data?.find((item) => item?.city === "Dubai");
  if (cityData !== undefined) {
    localStorage.setItem("cityDetails", JSON.stringify(cityData));
  }

  return (
    <HelmetProvider>
      <StripePaymentProvider>
        <Suspense fallback={<ProgressBar1 />}>
          <I18nextProvider i18n={i18n} >
            <Header />

            <div className="pagesWrapper">
              <Routes>
                {/**************  Start public Route *********************/}
                <Route path="/property-detail/:id" element={<PropertyDetail />} />
                <Route path="/property/checkout" element={<PropertyCheckout />} />
                <Route path="/property/payment" element={<PropertyPayment />} />
                <Route path="/verify-payment" element={<VerifyPayment />} />
                <Route path="/who-we-are" element={<About />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/landlord" element={<Landlord />} />
                {/* <Route path="/landlord/arabic" element={<LandlordArabic />} /> */}
                <Route path="/success-payment" element={<SuccessPayment />} />
                <Route path="/success-ancillary-payment" element={<SuccessAncillaryPayment />} />
                <Route path="/featured-towers/marina-vista" element={<TowerProfile />} />
                <Route path="/sitemap" element={<Sitemap />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsConditions />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/corporate-and-groups"
                  element={<BusinessGroupIndex />}
                >
                  <Route index element={<BusinessAndGroup />} />
                  <Route
                    path="corporate-inquiry"
                    element={<CorporateInquiry />}
                  />
                  <Route path="group-inquiry" element={<GroupInquiry />} />
                  <Route />
                </Route>
                <Route
                  path="/customer_feedback/:hashId"
                  element={<CustomerReview />}
                />
                <Route
                  path="/onboarding_feedback/:hashId"
                  element={<OwnerReview />}
                />
                <Route path="/manage-booking" element={<ManageBooking />} />
                <Route path="/long-term-stays" element={<LongTermStays />} />
                <Route path="/:city?" element={<Index />} />
                <Route path="/our-properties/:city?/:neighborhoods?/:towers?" element={<OurProperties />} />

                {/***************  End public Route  **********************/}

                <Route path="/404" element={<NotFoundPage />} />
                <Route path="/*" element={<Navigate to="/404" />} />
              </Routes>
            </div>

            <Routes>
              <Route path="/our-properties" />
            </Routes>
          </I18nextProvider>
        </Suspense>
      </StripePaymentProvider>
    </HelmetProvider>
  );
}

export default App;
