import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "../node_modules/design-system-silkhaus/dist/style.css";
import "./tailwind.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import Store from "./redux/store";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-image-lightbox/style.css";
import { GlobalContextProvider } from "./CommonComponent/GlobalContext";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <Router>
        <GlobalContextProvider>
          <App />
          <ToastContainer />
        </GlobalContextProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
