import { createUseStyles } from "react-jss";

const formatRating = (rating) => {
  return rating?.toLocaleString(undefined, {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  });
};

export default formatRating;

export const formatCurrency = (val, currency = "AED") => {
  const twoDecimalValue = Math.round(val) === val ? Number(val) : Number(val.toFixed(2));
  const value = !twoDecimalValue
    ? ""
    : twoDecimalValue.toLocaleString(undefined, {
        minimumFractionDigits: Math.round(twoDecimalValue) === twoDecimalValue ? 0 : 2,
      });

  return `${currency} ${value}`;
};

export const formatNumber = (val) => {
  const value = !val
  ? ""
  : val.toLocaleString(undefined, {
      minimumFractionDigits: Math.round(val) === val ? 0 : 2,
    })

  return `${value}`;

};


export const useStyles = createUseStyles({
  screen: {
    position: "absolute",
    left: 0,
    right: 0,
  },
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    // backgroundColor: "pink",
  },
  container: {
    maxWidth: "1280px",
    padding: "0px 24px 0 24px",

    "@media (min-width:767px)": {
      padding: "0px 40px 0px 40px",
    },

    "@media (min-width:1280px)": {
      maxWidth: "1280px",
      padding: "0px 80px 0 80px",
    },
  },
  sectionWrapper: {
    // backgroundColor: "pink",
    paddingTop: 20,
    marginTop: 10,
    paddingLeft: 60,
    paddingRight: 60,
  },
  sectionWrapperPaddingBottom: {
    paddingBottom: 60,
    // backgroundColor: "pink",
    paddingTop: 20,
    marginTop: 10,
  },
});

export const GetUTCTimeDiff = (endDate) => {
  const currTime = Date.now();
  const endTime = new Date(endDate).getTime();

  // Calculate the difference in milliseconds
  var timeDifference = endTime - currTime;

  // Convert milliseconds to seconds
  return Math.floor(timeDifference / 1000);
};

export const ReactSelectStyle = () => {
  return {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: 0,
      backgroundColor: "white",
      cursor: state.isDisabled ? "not-allowed" : "auto",
      height: 42,
      borderColor:
        state.menuIsOpen || state.isFocused
          ? "#000000"
          : state.isDisabled
          ? "rgba(0, 0, 0, 0.05)"
          : "rgba(0, 0, 0, 0.25)",
      boxShadow: null,
      "&:hover": {
        borderColor: "rgba(0, 0, 0, 0.5)",
        cursor: state.isDisabled ? "not-allowed" : "auto",
      },
    }),
    container: (baseStyles) => ({
      ...baseStyles,
      borderRadius: 0,
      height: "42px",
    }),
    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      height: 40,
      cursor: state.isDisabled ? "not-allowed" : "auto",
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      height: 38,
      margin: 0,
      padding: 0,
      cursor: state.isDisabled ? "not-allowed" : "auto",
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      color: "#000",
      cursor: state.isDisabled ? "not-allowed" : "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#6d36a9" : "white",
      color: state.isSelected ? "white" : "#000",
      "&:hover": {
        backgroundColor: "#6d36a9", // Change the hover background color
        color: "white", // Change the hover text color
      },
    }),
  };
};

export const formatPriceForCalendar = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num.toString();
  }
};

export const scrollToSection = (sectionId, delay = 0, position = 'center') => {
  if (delay === 0) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: position });
    }
  } else {
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if(section) {
        section.scrollIntoView({ behavior: "smooth", block: position });
      }
    }, delay);
  }
};
