import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE_ITEMS, TAGRID } from '../utils/constants';

const SKYWARD_STORAGE_KEY = LOCAL_STORAGE_ITEMS.SKYWARD_STORAGE_KEY;
const SKYWARD_TTL = process.env.REACT_APP_SKYWARD_TTL;

const useSkywards = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tagrid = params.get(TAGRID);

    if (tagrid) {
      // If tagrid present in the URL, create/update it in local storage
      const skywardData = {
        tagrid,
        timestamp: new Date().getTime()
      };
      localStorage.setItem(SKYWARD_STORAGE_KEY, JSON.stringify(skywardData));
      // Remove the tagrid parameter from the URL
      params.delete(TAGRID);
      navigate({ search: params.toString() }, { replace: true });
    }
  }, [location, navigate]);
};

export default useSkywards;
