import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../API";

export const fetch_cities = createAsyncThunk("/cities", async () => {
  try {
    let res = await axiosInstance.get("listings/cities/");

    return res?.data;
  } catch (error) {
    console?.log(error);
  }
});

const initialState = {
  //cites
  cities_data: null,
  cities_status: "idle",
  pricing_filters: null,
};

export const location = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers: {
    //cites
    [fetch_cities.pending]: (state) => {
      state.cities_status = "loading";
    },
    [fetch_cities.fulfilled]: (state, { payload }) => {
      state.cities_status = "idle";
      state.cities_data = payload?.results;
      state.pricing_filters = payload?.pricing_filters
    },
    [fetch_cities.rejected]: (state) => {
      state.cities_status = "idle";
    },
  },
});
