const cdnBaseUrl = "https://cdn.silkhaus.com/"
export const rightArrowPurpleIcon = cdnBaseUrl + "icons/right_arrow_purple.svg"
export const whySilkhausBanner = cdnBaseUrl + "homepage/why_silkhaus_banner.jpg"
export const forbesLogo = cdnBaseUrl + "homepage/forbes_logo.png"
export const whatsappLogoIcon = cdnBaseUrl + "icons/whatsapp_logo.svg"
export const skiftLogo = cdnBaseUrl + "homepage/skift_logo.png"
export const techCrunchLogo = cdnBaseUrl + "homepage/techcrunch_logo.png"
export const fastCompanyLogo = cdnBaseUrl + "homepage/FastCompany.png"
export const arabianBusinessLogo = cdnBaseUrl + "homepage/arabian_business_logo.png"
export const wifiIcon = cdnBaseUrl + "icons/wifi.svg"
export const workspaceIcon = cdnBaseUrl + "icons/workspace.svg"
export const bathtubIcon = cdnBaseUrl + "icons/bathtub.svg"
export const bedIcon = cdnBaseUrl + "icons/bed.svg"
export const kitchenIcon = cdnBaseUrl + "icons/kitchen.svg"
export const lockIcon = cdnBaseUrl + "icons/lock.svg"
export const starIcon = cdnBaseUrl + "icons/star.svg"
export const coffeeMugIcon = cdnBaseUrl + "icons/coffee_mug.svg"
export const quoteIcon = cdnBaseUrl + "icons/quote.svg"
export const quoteRotatedIcon = cdnBaseUrl + "icons/quote_rotated.svg"
export const silkhausLogoFooter = cdnBaseUrl + "common/silkhaus_logo_footer.svg"
export const mapLocationMarker = cdnBaseUrl + "common/map_location_marker.svg"
export const instagramWhiteIcon = cdnBaseUrl + "icons/instagram_white.svg"
export const linkedinWhiteIcon = cdnBaseUrl + "icons/linkedin_white.svg"
export const twitterWhiteIcon = cdnBaseUrl + "icons/twitter_white.svg"
export const facebookWhiteIcon = cdnBaseUrl + "icons/facebook_white.svg"
export const guestIcon = cdnBaseUrl + "icons/guest.svg"
export const houseIcon = cdnBaseUrl + "icons/house.svg"
export const areaIcon = cdnBaseUrl + "icons/area.svg"
export const leftArrowIcon = cdnBaseUrl + "icons/left-arrow.svg"
export const rightArrowWhiteIcon = cdnBaseUrl + "icons/right_arrow_white.svg"
export const rightArrowBlackIcon = cdnBaseUrl + "icons/arrow_right_black.svg"
export const leftArrowBlackIcon = cdnBaseUrl + "icons/arrow_left_black.svg"
export const uaeFlag = cdnBaseUrl + "common/uae_flag.svg"
export const cnyFlag = cdnBaseUrl + "common/cny_flag.svg"
export const eurFlag = cdnBaseUrl + "common/eur_flag.svg"
export const sarFlag = cdnBaseUrl + "common/sar_flag.svg"
export const indFlag = cdnBaseUrl + "common/ind_flag.png"
export const usaFlag = cdnBaseUrl + "common/usa_flag.svg"
export const silkhausLogoHeader = cdnBaseUrl + "common/silkhaus_logo_header.svg"
export const hamburgerMenu = cdnBaseUrl + "common/hamburger_menu.svg"
export const topNavBarCalendarMobile = cdnBaseUrl + "common/mobile/calendar_top_nav.jpg"
export const topNavBarCalendarDesktop = cdnBaseUrl + "common/desktop/calendar_top_nav.jpg"
export const downArrowIcon = cdnBaseUrl + "icons/down-arrow.svg"
export const searchBlackIcon = cdnBaseUrl + "icons/search_black.svg"
export const searchWhiteIcon = cdnBaseUrl + "icons/search_white.svg"
export const facebookBlackIcon = cdnBaseUrl + "icons/facebook_black.svg"
export const linkedinBlackIcon = cdnBaseUrl + "icons/linkedin_black.svg"
export const twitterBlackIcon = cdnBaseUrl + "icons/twitter_black.svg"
export const instagramBlackIcon = cdnBaseUrl + "icons/instagram_black.svg"
export const closeMenuIcon = cdnBaseUrl + "icons/close_menu.svg"
export const homepageBanner = cdnBaseUrl + "homepage/homepage_banner.jpg"
export const downArrowGreyIcon = cdnBaseUrl + "icons/down_arrow_grey.svg"
export const whyPartnerSilkhaus = cdnBaseUrl + "landlord/why_partner_silkhaus.png"
export const getInTouch = cdnBaseUrl + "landlord/get_in_touch.jpg"
export const landlordBanner = cdnBaseUrl + "landlord/landlord_banner.png"
export const filterBlackIcon = cdnBaseUrl + "icons/filter_black.svg"
export const buildingSelectedIcon = cdnBaseUrl + "icons/building_selected.svg"
export const buildingPurpleIcon = cdnBaseUrl + "icons/building_purple.svg"
export const infoRed = cdnBaseUrl + "icons/info_red.svg"
export const locationPurple = cdnBaseUrl + "icons/location_purple.svg"
export const editPurpleOutline = cdnBaseUrl + "icons/edit_purple_outline.svg"
export const cancelPurpleOutline = cdnBaseUrl + "icons/cancel_purple_outline.svg"
export const guestGrey = cdnBaseUrl + "icons/guest_grey.svg"
export const calendarGrey = cdnBaseUrl + "icons/calendar_grey.svg"
export const checkinGrey = cdnBaseUrl + "icons/checkin_grey.svg"
export const checkoutGrey = cdnBaseUrl + "icons/checkout_grey.svg"
export const statusGrey = cdnBaseUrl + "icons/status_grey.svg"
export const coinsGrey = cdnBaseUrl + "icons/coins_grey.svg"
export const homepageCarouselBannerDesktop1 = cdnBaseUrl + "homepage/desktop/banner_1.webp"
export const homepageCarouselBannerDesktop2 = cdnBaseUrl + "homepage/desktop/banner_2.webp"
export const homepageCarouselBannerDesktop3 = cdnBaseUrl + "homepage/desktop/banner_3.webp"
export const homepageCarouselBannerMobile1 = cdnBaseUrl + "homepage/mobile/banner_1.webp"
export const homepageCarouselBannerMobile2 = cdnBaseUrl + "homepage/mobile/banner_2.webp"
export const homepageCarouselBannerMobile3 = cdnBaseUrl + "homepage/mobile/banner_3.webp"
export const homepageCarouselBannerTablet1 = cdnBaseUrl + "homepage/tablet/banner_1.webp"
export const homepageCarouselBannerTablet2 = cdnBaseUrl + "homepage/tablet/banner_2.webp"
export const homepageCarouselBannerTablet3 = cdnBaseUrl + "homepage/tablet/banner_3.webp"

// Institutional Landlord Page
export const inlDesktopBackground = cdnBaseUrl + "institutional-landlord/desktop/background.jpg"
export const inlDesktopBackgroundArabic = cdnBaseUrl + "institutional-landlord/desktop/background_arabic.jpg"
export const inlDesktopJourneyWithSilkhaus = cdnBaseUrl + "institutional-landlord/desktop/journey_with_silkhaus.png"
export const inlDesktopJourneyWithSilkhausArabic = cdnBaseUrl + "institutional-landlord/desktop/journey_with_silkhaus_arabic.png"
export const inlDesktopBenefitsGallery1 = cdnBaseUrl + "institutional-landlord/desktop/benefits_gallery_1.png"
export const inlDesktopBenefitsGallery2 = cdnBaseUrl + "institutional-landlord/desktop/benefits_gallery_2.png"
export const inlDesktopBenefitsGallery3 = cdnBaseUrl + "institutional-landlord/desktop/benefits_gallery_3.png"
export const inlDesktopBenefitsGallery4 = cdnBaseUrl + "institutional-landlord/desktop/benefits_gallery_4.png"
export const inlDesktopBenefitsGallery5 = cdnBaseUrl + "institutional-landlord/desktop/benefits_gallery_5.png"
export const inlDesktopBenefitsGallery6 = cdnBaseUrl + "institutional-landlord/desktop/benefits_gallery_6.png"
export const inlDesktopHeadOfRealEstate = cdnBaseUrl + "institutional-landlord/desktop/patrick.jpg"
export const inlDesktopGrowYourBusiness = cdnBaseUrl + "institutional-landlord/desktop/grow_your_business.png"
export const inlTabletBenefitsGallery1 = cdnBaseUrl + "institutional-landlord/tablet/benefits_gallery_1.jpg"
export const inlTabletBenefitsGallery2 = cdnBaseUrl + "institutional-landlord/tablet/benefits_gallery_2.jpg"
export const inlTabletBenefitsGallery3 = cdnBaseUrl + "institutional-landlord/tablet/benefits_gallery_3.jpg"
export const inlTabletBenefitsGallery4 = cdnBaseUrl + "institutional-landlord/tablet/benefits_gallery_4.jpg"
export const inlTabletBenefitsGallery5 = cdnBaseUrl + "institutional-landlord/tablet/benefits_gallery_5.jpg"
export const inlTabletBenefitsGallery6 = cdnBaseUrl + "institutional-landlord/tablet/benefits_gallery_6.jpg"
export const inlMultiBuilding = cdnBaseUrl + "institutional-landlord/multi_building.gif"
export const inlEntireBuilding = cdnBaseUrl + "institutional-landlord/entire_building.gif"
export const inlPartialBuilding = cdnBaseUrl + "institutional-landlord/partial_building.gif"
export const inlSingleProperty = cdnBaseUrl + "institutional-landlord/single_property.gif"
export const inlOtaAirbnbLogo = cdnBaseUrl + "institutional-landlord/airbnb_logo.png"
export const inlOtaAgodaLogo = cdnBaseUrl + "institutional-landlord/agoda_logo.png"
export const inlOtaBdcLogo = cdnBaseUrl + "institutional-landlord/bdc_logo.png"
export const inlOtaExpediaLogo = cdnBaseUrl + "institutional-landlord/expedia_logo.png"
export const inlOtaGoogleLogo = cdnBaseUrl + "institutional-landlord/google_logo.png"
export const inlOtaMmtLogo = cdnBaseUrl + "institutional-landlord/mmt_logo.png"
export const inlOtaMarriottBonvoyLogo = cdnBaseUrl + "institutional-landlord/marriott_bonvoy_logo.png"
export const inlOtaTdcLogo = cdnBaseUrl + "institutional-landlord/tdc_logo.png"
export const inlOtaTravelStaytionLogo = cdnBaseUrl + "institutional-landlord/travel_staytion_logo.png"
export const inlOtaVrboLogo = cdnBaseUrl + "institutional-landlord/vrbo_logo.png"
export const inlCheckMarkCircular = cdnBaseUrl + "institutional-landlord/check_mark_circular.svg"
export const headOfRealEstateImg = cdnBaseUrl + "marina-vista/joanna.jpg"
export const careemQRCode = cdnBaseUrl + "ancillary_services/careem_qr_code.png"
export const playStore = cdnBaseUrl + "icons/app_store.svg"
export const appStore = cdnBaseUrl + "icons/google_play.svg"
export const careemDesktopBanner = cdnBaseUrl + "ancillary_services/desktop/careem_banner.png"
export const careemTabletBanner = cdnBaseUrl + "ancillary_services/tablet/careem_banner.png"
export const careemMobileBanner = cdnBaseUrl + "ancillary_services/mobile/careem_banner.png"

// Promo dialog
export const promoDialogDesktopBanner = cdnBaseUrl + "promo/desktop/banner.png";
export const promoDialogMobileBanner = cdnBaseUrl + "promo/mobile/banner.png";
export const extra_cleaning_new = cdnBaseUrl + "ancillary_services/extra_cleaning_new.png"
