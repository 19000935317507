import React, { createContext, useContext } from "react";
import { useLocation } from "react-router-dom";

const StripePaymentContext = createContext({
  intentId: "",
  clientSecret: "",
  clearPaymentContext: () => {},
  reservationConfirmed: false,
  confirmReservation: () => {},
});

export const useStripePayment = () => useContext(StripePaymentContext);

export const StripePaymentProvider = ({ children }) => {
  const { search } = useLocation();
  const [intentId, setIntentId] = React.useState("");
  const [clientSecret, setClientSecret] = React.useState("");
  const [reservationConfirmed, setReservationConfirmed] = React.useState(false);

  const clearPaymentContext = () => {
    setIntentId("");
    setClientSecret("");
    setReservationConfirmed(false);
  };

  const confirmReservation = () => {
    setReservationConfirmed(true);
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams?.get("payment_intent"))
      setIntentId(searchParams.get("payment_intent"));
    if (searchParams?.get("payment_intent_client_secret"))
      setClientSecret(searchParams.get("payment_intent_client_secret"));
  }, [search]);

  return (
    <StripePaymentContext.Provider
      value={{
        intentId,
        clientSecret,
        clearPaymentContext,
        reservationConfirmed,
        confirmReservation,
      }}
    >
      {children}
    </StripePaymentContext.Provider>
  );
};
